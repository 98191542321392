import React from "react"
import "../styles/home.scss"

const SectionFour = props => (
  <div class="sectionFour">
    <p class="title">Valores</p>
    <p class="subtitle">
      Nuestros valores nos definen para poder brindarte la mejor calidad de servicio
    </p>
    <div class="iconsArea">
      <div class="icons">
        <p>Honestidad</p>
      </div>
      <div class="icons">
        <p>Respeto</p>
      </div>
      <div class="icons">
        <p>Compromiso</p>
      </div>
      <div class="icons">
        <p>
          Trabajo en Equipo
        </p>
      </div>
    </div>
  </div>
)

export default SectionFour
